import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import newsArticleOne from "../images/news/shuttleid-partnership-sm.jpg"
import newsArticleTwo from "../images/news/emmerdale-studio-experience-sm.jpg"
import newsArticleThree from "../images/news/wakefield-free-bus-sm.jpg"
import imgLeedsTv from "../images/news/leeds-tv-sm.png"
import imgUniLeeds from "../images/news/uni-leeds-sm.png"
import imgLeedsMetUni from "../images/news/leeds-met-uni-sm.png"
import img60Coaches from "../images/news/60-coaches-sm.jpg"
import imgCapita from "../images/news/capita-sm.jpg"
import imgNhs from "../images/news/nhs-sm.png"
import imgBbc from "../images/news/bbc-spoty-coaches-sm.jpg"
import imgRidings from "../images/news/ridings-sm.jpg"
import imgChinaOlympicTeam from "../images/news/tetleys-china-olympic-team-sm.jpg"
import imgWheelchair from "../images/news/wheelchair-access-sm.jpg"
import imgMybus from "../images/news/mybus-sm.jpg"
import imgTetleys from "../images/news/tetleys-sm.jpg"
import imgDigitalTransformation from "../images/news/digital-periodic-maintenance-sm.jpg"
import imgCommitmentToSafety from "../images/news/commitment-to-safety-sm.jpg"
import imgHeckmondWikePass from '../images/news/heckmondwike-grammar-school-bus-sm.jpg'
import imgLivingWage from '../images/news/tetleys-coaches-living-wage-employer-accreditation-sm.png'

class NewsIndexPage extends Component {

  componentDidMount() {
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="News" description="The latest news straight from Tetley's Coaches." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">News</h1>
            </div>

            <div className="page-news__articles">
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="100" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/commitment-to-safety/">
                  <img src={imgCommitmentToSafety} alt="" />
                  <div className="page-news__article__content">
                    <p>Commitment to safety</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/tetleys-coaches-achieves-living-wage-employer-accreditation/">
                  <img src={imgLivingWage} alt="" />
                  <div className="page-news__article__content">
                    <p>Tetley's Achieve Living Wage Employer Accreditation</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/heckmondwike-grammar-bus-pass/">
                  <img src={imgHeckmondWikePass} alt="" />
                  <div className="page-news__article__content">
                    <p>Heckmondwike Grammar school bus passes on sale!</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="100" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/digital-transformation/">
                  <img src={imgDigitalTransformation} alt="" />
                  <div className="page-news__article__content">
                    <p>Digital transformation</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="100" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/new-website-launch/">
                  <img src={imgTetleys} alt="" />
                  <div className="page-news__article__content">
                    <p>Tetley's launch new website</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="100" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/tetleys-partner-with-shuttleid/">
                  <img src={newsArticleOne} alt="" />
                  <div className="page-news__article__content">
                    <p>Tetley's partner with ShuttleID </p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="200" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/emmerdale-tour-shuttle-bus/">
                  <img src={newsArticleTwo} alt="" />
                  <div className="page-news__article__content">
                    <p>Emmerdale tour shuttle bus</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/wakefield-free-bus-relaunch/">
                  <img src={newsArticleThree} alt="" />
                  <div className="page-news__article__content">
                    <p>Wakefield free bus relaunch</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/tetleys-advert-on-made-in-leeds/">
                  <img src={imgLeedsTv} alt="" />
                  <div className="page-news__article__content">
                    <p>Tetley's Coaches advertise on 'Made in Leeds'</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/leeds-uni-transport-contract-extended/">
                  <img src={imgUniLeeds} alt="" />
                  <div className="page-news__article__content">
                    <p>University of Leeds' transport contract extended</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/operator-licence-increase-to-60-vehicles/">
                  <img src={img60Coaches} alt="" />
                  <div className="page-news__article__content">
                    <p>Operator Licence increased to 60 Vehicles</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/tetleys-win-capita-contract/">
                  <img src={imgCapita} alt="" />
                  <div className="page-news__article__content">
                    <p>Tetley's win Capita contract</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/tetleys-win-mid-yorkshire-nhs-trust-contract/">
                  <img src={imgNhs} alt="" />
                  <div className="page-news__article__content">
                    <p>Tetley's win Mid-Yorkshire NHS Trust contract</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/tetleys-supply-coaches-for-bbc-spoty/">
                  <img src={imgBbc} alt="" />
                  <div className="page-news__article__content">
                    <p>Tetley's supply coaches for BBC Sports Personality of the Year</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/tetleys-become-leeds-met-uni-transport-supplier/">
                  <img src={imgLeedsMetUni} alt="" />
                  <div className="page-news__article__content">
                    <p>Tetley's become Leeds Met Uni's transport supplier</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/tetleys-become-leeds-uni-transport-supplier/">
                  <img src={imgUniLeeds} alt="" />
                  <div className="page-news__article__content">
                    <p>Tetley's become University of Leeds' transport supplier</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/tetleys-acquire-ridings-coaches/">
                  <img src={imgRidings} alt="" />
                  <div className="page-news__article__content">
                    <p>Tetley's acquire Ridings Coaches</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/tetleys-provide-transport-china-olympic-team/">
                  <img src={imgChinaOlympicTeam} alt="" />
                  <div className="page-news__article__content">
                    <p>Tetley's provide transport for Chinese olympic team</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/tetleys-add-wheelchair-accessible-coach/">
                  <img src={imgWheelchair} alt="" />
                  <div className="page-news__article__content">
                    <p>Tetley's add wheelchair accessible coach to fleet</p>
                  </div>
                </Link>
              </div>
              <div className="page-news__article" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <Link className="page-news__article-link btn-grow" to="/news/tetleys-join-metro-mybus-school-transport-scheme/">
                  <img src={imgMybus} alt="" />
                  <div className="page-news__article__content">
                    <p>Tetley's join Metro's "Mybus" school transport scheme</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>

        </div>

      </Layout>
    );
  }
}

export default NewsIndexPage
